import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyC1iF0ZLO3EDAQHgAL0DxwxlPxZ1rLeP1I",
    authDomain: "seogles-proto.firebaseapp.com",
    databaseURL: "https://seogles-proto.firebaseio.com",
    projectId: "seogles-proto",
    storageBucket: "seogles-proto.appspot.com",
    messagingSenderId: "686474763863",
    appId: "1:686474763863:web:b41f2fb4411c89b379cd12"
});

const auth = firebaseApp.auth();
const db = firebaseApp.database();
const storage = firebaseApp.storage();

export { auth, db, storage };
